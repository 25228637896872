import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    const navigateToLogin = () => {
        // 👇️ navigate to /contacts
        navigate('/login');
      };

    return (
      <div>
        <h1>Please login to use Admin EasySalon Dashboard</h1>
        <button onClick={navigateToLogin}>Login</button>
      </div>
    );
  };

  export default Home;
